
const INDEX_LATITUDE_CORDE_INTERIEURE = 1;
const INDEX_LONGITUDE_CORDE_INTERIEURE = 2;
const INDEX_LATITUDE_CORDE_A_X_METRE = 3;
const INDEX_LONGITUDE_CORDE_A_X_METRE = 4;
const INDEX_LATITUDE_CORDE_EXTERIEURE = 5;
const INDEX_LONGITUDE_CORDE_EXTERIEURE = 6;
const INDEX_NOM_PORTE = 7;

const FILTER_MODE_CURVE = 'curve';
const FILTER_MODE_NUMBER = 'number';

function isPorte(line) {
    const nomPorte = line[INDEX_NOM_PORTE]?.trim(); // "0" ou "PCDEP" ou "PCARR" ou "PCXXXX" ou ... (porte d'obstacle, etc.)
    if (!nomPorte) {
        // A priori pas de undefined ou null possible, mais sait on jamais ...
        return false;
    }
    const isPorteDep = nomPorte === 'PCDEP';
    const isPorteArr = nomPorte === 'PCARR';
    const isPorteDistance = (Number(nomPorte) !== 0 && parseInt(nomPorte.substr(2)) )
    return isPorteDep || isPorteArr || isPorteDistance;
}

function coef(pt1, pt2) {
    return (pt1[INDEX_LATITUDE_CORDE_INTERIEURE] - pt2[INDEX_LATITUDE_CORDE_INTERIEURE])
        /  (pt1[INDEX_LONGITUDE_CORDE_INTERIEURE] - pt2[INDEX_LONGITUDE_CORDE_INTERIEURE]);
}

function getCoefCurve(pt1, pt2, pt3) {
    const coef1 = coef(pt1, pt2);
    const coef2 = coef(pt1, pt3);
    return Math.abs(1 - (coef1/coef2));
}

function filterCurving(portes, autres, coefCurve) {
    let pindex = 0;
    while (pindex < portes.length - 2) {
        const pt1 = portes[pindex + 0];
        const pt2 = portes[pindex + 1];
        const pt3 = portes[pindex + 2];
        const coefVirage = getCoefCurve(pt1, pt2, pt3);
        if (coefVirage < coefCurve) {
            portes.splice(pindex + 1, 1);
        } else {
            pindex++;
        }
    }

    let aindex = 0;
    while (aindex < autres.length - 2) {
        const pt1 = autres[aindex + 0];
        const pt2 = autres[aindex + 1];
        const pt3 = autres[aindex + 2];
        const coefVirage = getCoefCurve(pt1, pt2, pt3);
        if (coefVirage < coefCurve) {
            autres.splice(aindex + 1, 1);
        } else {
            aindex++;
        }
    }

    return {
        portes,
        autres,
    }
}
function filterNumber(portes, autres, nbObjectOnMap) {
    const NB_OBJETS_PAR_PORTE = 4;
    const nbObjetsDePorte = NB_OBJETS_PAR_PORTE * portes.length;
    const nbPorteASupprimer = Math.max(0 , nbObjetsDePorte - nbObjectOnMap) / NB_OBJETS_PAR_PORTE;
    const NB_OBJETS_PAR_AUTRE = 2;
    const nbObjetsDeAutre = NB_OBJETS_PAR_AUTRE * autres.length;
    const maxNbObjetsAutresOnMap = Math.max(0 , nbObjectOnMap - nbObjetsDePorte) / NB_OBJETS_PAR_AUTRE;
    const nbAutresASupprimer = Math.max(0, nbObjetsDeAutre - maxNbObjetsAutresOnMap )/ NB_OBJETS_PAR_AUTRE;

    const coefPorte = nbPorteASupprimer / portes.length;
    const coefAutre = nbAutresASupprimer / autres.length;

    let nbASupprimer = 0
    const indexesPorteAGarder = [0, portes.length - 1];
    for(let index = 1; index < portes.length - 1; index++) {
        const coefActuel = (nbASupprimer + 1) / (nbASupprimer + indexesPorteAGarder.length)
        if (coefActuel < coefPorte) {
            nbASupprimer++;
        } else {
            indexesPorteAGarder.push(index);
        }
    }
    nbASupprimer = 0
    const indexesAutreAGarder = [];
    for(let index = 0; index < autres.length; index++) {
        const coefActuel = (nbASupprimer + 1) / (nbASupprimer + indexesAutreAGarder.length)
        if (coefActuel < coefAutre) {
            nbASupprimer++;
        } else {
            indexesAutreAGarder.push(index);
        }
    }
    return {
        portes: portes.filter((_, index) => indexesPorteAGarder.includes(index)),
        autres: autres.filter((_, index) => indexesAutreAGarder.includes(index)),
    };
}

function filter(trackContent, {modes, nbObjectOnMap, coefCurve}) {
    const lines = trackContent
        .split(/\r?\n/)
        .map(line => line.split(';'))
        .filter(values => values.length !== 0)
        .filter(values => values.length !== 1);
    let {portes, autres} = lines
        .reduce(
            (split, line) => (isPorte(line) ? split.portes.push(line) : split.autres.push(line)) && split,
            {portes: [], autres: []}
        );
    
    // 1 Porte : 2 points, un trait, une pop-pup
    // 1 Autre : 2 points

    console.log('-----------------------')
    console.log('NbPorte', portes.length)
    console.log('nbObjetsDePorte', 4 * portes.length)
    console.log('NbAutre', autres.length)
    console.log('nbObjetsDeAutre', 2 * autres.length)
    console.log('-->')

    const initialPortesLength = portes.length
    const initialAutresLength = autres.length

    if (modes.includes(FILTER_MODE_CURVE)) {
        const data = filterCurving(portes, autres, coefCurve);
        portes = data.portes;
        autres = data.autres;
    }
    if (modes.includes(FILTER_MODE_NUMBER)) {
        const data = filterNumber(portes, autres, nbObjectOnMap);
        portes = data.portes;
        autres = data.autres;
    }

    console.log(`Au final, je garde ${portes.length}/${initialPortesLength} portes.`);
    console.log(`Au final, je garde ${autres.length}/${initialAutresLength} autres.`);

    let inners = [];
    let outers = [];
    let gates = [];
    portes
        .forEach((values, index) => {
            let positionInterieure = {
                lat: parseFloat(values[INDEX_LATITUDE_CORDE_INTERIEURE]),
                lng: parseFloat(values[INDEX_LONGITUDE_CORDE_INTERIEURE]),
            };
            let positionExterieure = {
                lat: parseFloat(values[INDEX_LATITUDE_CORDE_EXTERIEURE]),
                lng: parseFloat(values[INDEX_LONGITUDE_CORDE_EXTERIEURE]),
            };
            let label = values[INDEX_NOM_PORTE];
            inners.push({index, position: positionInterieure, type: 'gate', label});
            outers.push({index, position: positionExterieure, type: 'gate', label});
            gates.push({index, positions: [positionInterieure, positionExterieure]})
        });
    autres
        .forEach((values, index) => {
            let positionInterieure = {
                lat: parseFloat(values[INDEX_LATITUDE_CORDE_INTERIEURE]),
                lng: parseFloat(values[INDEX_LONGITUDE_CORDE_INTERIEURE]),
            };
            let positionExterieure = {
                lat: parseFloat(values[INDEX_LATITUDE_CORDE_EXTERIEURE]),
                lng: parseFloat(values[INDEX_LONGITUDE_CORDE_EXTERIEURE]),
            };
            inners.push({index, position: positionInterieure, type: 'point'});
            outers.push({index, position: positionExterieure, type: 'point'});
        });

    return {
        inners,
        outers,
        gates,
    };
}

const ObjectFilterService = {
    FILTER_MODE_CURVE,
    FILTER_MODE_NUMBER,
    filter,
};

export default ObjectFilterService;