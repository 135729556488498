import {Slider} from "antd";

export default function MLNbObjectSlider({id, text, onChange, value, values}) {
    return (
        <div className="item item-double">
            <label className="label" htmlFor={id}>{text}</label>
            <Slider
                className="ml-slider"
                min={0}
                max={values.length-1}
                step={1}
                value={values.indexOf(value)}
                dots={true}
                onChange={ index => onChange(values[index]) }
                tooltip={{
                    // TODO : seulement ANTD 4.23 ... on ets en 4.15... faudrait faire un upgrade
                    formatter : index =>  `${values[index]} objects // ${values[index]/4} doors`,
                }}
                />
        </div>
    )
}