import React, {useEffect, useRef, useState} from "react";
import track from "../services/track.service";
import TrackDataList from "../components/trackDataList";
import Loader from "../components/loader"
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {GoogleMap, InfoWindow, LoadScript, Marker, Polyline,} from "@react-google-maps/api";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {useScreenSize} from "../components/screensizeHook"

const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
};

var defaultMapOptions = {
    streetViewControl: false,
    scaleControl: false,
    fullscreenControl: false,
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 5,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
}

const icon_size = 20;

export default function RaceTrack() {
    const {t} = useTranslation();
    // eslint-disable-next-line
    const [loading, set_loading] = useState(true);
    const [MarkerList, setMarkerList] = useState([]);
    const [MarkerList_ext, setMarkerList_ext] = useState([]);
    const [MPipeline, setPipeline] = useState([]);
    const [mapCenter, setMapCenter] = useState();
    // eslint-disable-next-line
    const [mapZoom, setMapZoom] = useState(17);
    const [mapOptions, setMapOptions] = useState(false);
    // eslint-disable-next-line
    const [selectedTrackUuid, setSelectedTrackUuid] = useState();
    const data_list_ref = useRef();
    const [map, setmap] = useState();
    const history = useHistory();

    // add track ------------------------------------------------------

    const add_track = () => {
        history.push("/track_form/");
    };

    const add_venue = () => {
        history.push("/venue_form/");
    };

    // onMapLoad -------------------------------------------------------

    const onMapLoad = (map) => {
        setmap(map);
        map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        set_loading(false);
    };

    const getTrack = async (uuid) => {
        track
            .getContent(uuid)
            .then((r) => {
                if (r.data.content) {
                    let arrayMark = [];
                    let arrayMark_ext = [];
                    let labelPipeline = [];
                    const lines = r.data.content.split(/\r?\n/);
                    // remove empty line at the end
                    if (lines[lines.length - 1].length === 0) {
                        lines.pop();
                    }
                    for (let p = 1; p < lines.length; p++) {
                        const values = lines[p].split(';');
                        let posM = {
                            lat: parseFloat(values[1]),
                            lng: parseFloat(values[2]),
                        };

                        let posM_ext = {
                            lat: parseFloat(values[5]),
                            lng: parseFloat(values[6]),
                        };

                        if (p === 1) {
                            map.setCenter(
                                new window.google.maps.LatLng(posM.lat, posM.lng, mapZoom)
                            );
                        }

                        if ((values[7].trim() === 'PCDEP' || values[7].trim() === 'PCARR') ||
                            (Number(values[7]) !== 0 && parseInt(values[7].substr(2)))) {
                            arrayMark.push(
                                <Marker
                                    key={`marker_${p}`}
                                    position={posM}
                                    icon={{
                                        url: `${process.env.PUBLIC_URL}/images/icons/green_dot.png`,
                                        scaledSize: new window.google.maps.Size(icon_size, icon_size)
                                    }}>
                                    <p>{values[7]}</p>
                                    <InfoWindow
                                        position={posM}
                                        clickable={true}>
                                        <p>{values[7]}</p>
                                    </InfoWindow>
                                </Marker>
                            );
                            labelPipeline.push(<Polyline key={`polyline_${p}`} path={[posM, posM_ext]}/>);
                            arrayMark_ext.push(
                                <Marker
                                    key={`marker_ext_${p}`}
                                    position={posM_ext}
                                    icon={{
                                        url: `${process.env.PUBLIC_URL}/images/icons/green_dot.png`,
                                        scaledSize: new window.google.maps.Size(icon_size, icon_size)
                                    }}
                                />
                            );
                        } else {
                            arrayMark.push(
                                <Marker
                                    key={`marker_${p}`}
                                    position={posM}
                                    icon={{
                                        url: `${process.env.PUBLIC_URL}/images/icons/blue_dot.png`,
                                        scaledSize: new window.google.maps.Size(icon_size, icon_size)
                                    }}
                                />
                            );

                            arrayMark_ext.push(
                                <Marker
                                    key={`marker_ext_${p}`}
                                    position={posM_ext}
                                    icon={{
                                        url: `${process.env.PUBLIC_URL}/images/icons/red_dot.png`,
                                        scaledSize: new window.google.maps.Size(icon_size, icon_size)
                                    }}
                                />
                            );
                        }
                    }
                    setMarkerList(arrayMark);
                    setMarkerList_ext(arrayMark_ext);
                    setPipeline(labelPipeline);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const selectTrackCallback = (trackUuid) => {
        setSelectedTrackUuid(trackUuid);
    }

    // useEffect ------------------------------------------------------

    useEffect(() => {
        if (window.google) {
            setMapOptions(defaultMapOptions);
            setMapCenter(JSON.parse(localStorage.getItem('mapDefaultCenter')));
            setMapZoom(Number(localStorage.getItem('mapDefaultZoom')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.google]);

    useEffect(() => {
        if (map && selectedTrackUuid) {
            getTrack(selectedTrackUuid).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, selectedTrackUuid]);

    const screenSize = useScreenSize();
    useEffect(() => {
        var mode = screenSize.width < 1000 ? 'cooperative': 'greedy';
        if (map && defaultMapOptions.gestureHandling !== mode) {
            defaultMapOptions.gestureHandling = mode;
            map.setOptions({ gestureHandling: mode });
        }
    }, [screenSize, map]);
    
    // render ---------------------------------------------------------
    return (
            <div className="pilotage_page_wrapper">
                <div className="pilotage_panel_data">
                    <div className="header">
                        <div className="sub_header_title">
                            <span className="subtitle">{t('Track.Subtitle')}</span>
                        </div>
                        <div className="sub_header_action">
                            <Button
                                onClick={add_track}
                                icon={<PlusCircleOutlined/>}>
                                {t('Track.AddButton')}
                            </Button>
                        </div>
                        <div className="sub_header_action">
                            <Button
                                onClick={add_venue}
                                icon={<PlusCircleOutlined/>}>
                                {t('Track.AddSiteButton')}
                            </Button>
                        </div>
                    </div>
                    <TrackDataList
                        ref={data_list_ref}
                        trackCallback={selectTrackCallback}/>
                </div>
                <div className="pilotage_panel_map">
                    <LoadScript
                        googleMapsApiKey="AIzaSyBRHVJET38Cx1CGYAkiErRdKh7gi2V4etM"
                        loadingElement={<Loader/>}>
                        <GoogleMap
                            id='parcoursMap'
                            mapContainerStyle={containerStyle}
                            zoom={mapZoom}
                            center={mapCenter}
                            onLoad={onMapLoad}
                            options={mapOptions}>
                            {MarkerList}
                            {MarkerList_ext}
                            {MPipeline}
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>
    );
}
