// LIBS
import React, {useContext, useEffect, useRef, useState} from "react";
import useInterval from "react-useinterval";
import {ApiOutlined, DeploymentUnitOutlined, SettingOutlined, WifiOutlined} from "@ant-design/icons";
import {Button, Modal, Switch, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import _ from "lodash";
// INTERNAL
import race from "../services/race.service";
// COMPONENTS
import MonitoringMap from "../components/monitoringMap"
import MonitoringMapToolbar from "../components/monitoringMapToolbar"
import MonitoringSearchCriteria from "../components/monitoringSearchCriteria"
import MonitoringDataList from "../components/monitoringDataList"
import {engine_broadcast} from "../classes/engine_broadcast";
import { getMonitoringColumns, MONITORING_COLUMNS, reloadDefaultMonitoringSettings, setMonitoringSetting } from "../services/monitoring-columns.service";

const RacingEngineConnectionContext = React.createContext({});
export const useRacingEngineConnectionContext = () => {
    return useContext(RacingEngineConnectionContext);
};
const Hpv2EngineContext = React.createContext({});
export const useHpv2EngineContext = () => {
    return useContext(Hpv2EngineContext);
};
const RacingEngineContext = React.createContext({});
export const useRacingEngineContext = () => {
    return useContext(RacingEngineContext);
};
const SelectedRaceContext = React.createContext({});
export const useSelectedRaceContext = () => {
    return useContext(SelectedRaceContext);
};
const IsEngineActiveContext = React.createContext({});
export const useIsEngineActiveContext = () => {
    return useContext(IsEngineActiveContext);
};
const PositionRaceDataContext = React.createContext({});
export const usePositionRaceDataContext = () => {
    return useContext(PositionRaceDataContext);
};
const TrackerSupervisionDataContext = React.createContext({});
export const useTrackerSupervisionDataContext = () => {
    return useContext(TrackerSupervisionDataContext);
};
const CurrentRaceTrackContext = React.createContext('');
export const useCurrentRaceTrackContext = () => {
    return useContext(CurrentRaceTrackContext);
};

function MonitoringSettings() {
    const {t} = useTranslation();
    const [data, setData] = useState({});

    useEffect(() => {
        getMonitoringColumns()
            .then(list => {
                setData(list.reduce((data, key) => ({
                    ...data,
                    [key]: true,
                }), {}))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function switchColumn(key) {
        setMonitoringSetting(key, !data[key])
            .then(() => getMonitoringColumns())
            .then(list => setData(list.reduce((data, key) => ({...data, [key]: true}), {})))
    }

    return (
        <div>
            <table>
                <tbody>
                    {
                        MONITORING_COLUMNS.map(column => (
                            <tr key={column.value}>
                                <td>{t(column.label)}</td>
                                <td>
                                    <Switch
                                        checked={data[column.value]}
                                        disabled={column.required}
                                        onChange={() => switchColumn(column.value)}
                                        />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default function PrivateMonitoring() {
    const {t} = useTranslation();

    const [hpv2Connection, setHpv2Connection] = useState(false);
    const [engineConnection, setEngineConnection] = useState(false);
    const [hpv2Engine, setHpv2Engine] = useState(false);
    const [racingEngine, setRacingEngine] = useState(false);
    const [isEngineActive, setIsEngineActive] = useState(false);
    const [selectedRace, setSelectedRace] = useState();
    const [trackerSupervisionData, setTrackerSupervisionData] = useState([]);
    const [positionRaceData, setPositionRaceData] = useState([]);
    const [raceTrack, setRaceTrack] = useState('');
    const [columnVersion, setColumnVersion] = useState(0);
    const webSocket = useRef();

    // engine broadcast reciever --------------------------------------
    const wsebReceive = (self, res) => {
        let cmd = res[0];
        let data = res[1];
        if (_.isEmpty(self)) {
            return;
        }
        switch (cmd) {
            // ======== from WSEB =========
            case "ping":
                setHpv2Connection(data.hpv2_connected);
                setEngineConnection(data.python_connected);
                break;
            case "position_data":
                if (data.raw_position || data.computed_position) {
                    setPositionRaceData(data);
                }
                break;
            case "tracker_supervision":
                setTrackerSupervisionData(data);
                break;
            // ========= DEFAULT =========
            default:
                break;

        }
    }

    const getActivatedRace = () => {
        race.getActivatedRace()
            .then(race => {
                if (race && race.data && race.data !== 'none') {
                    // if race is activated server side, init monitoring with appropriate state
                    setSelectedRace(race.data.uuid);
                    setIsEngineActive(true);
                } else {
                    setIsEngineActive(false);
                }
            })
            .catch(err => {
                console.log('ERROR', err);
            });
    }

    const selectRace = (selectedRaceUuid) => {
        if (selectedRaceUuid) {
            race.selectRace(selectedRaceUuid)
                .then(r => {
                    setRaceTrack(r.data.track.content);
                })
                .catch(err => {
                    console.log('ERROR', err);
                    alert('Parcours ou ICP non trouvé');
                });
        }
    }

    const connectSocket = () => {
        webSocket.current = new engine_broadcast();
        webSocket.current.set_callback(wsebReceive)
        webSocket.current.connect();
        window.addEventListener('beforeunload', closeSocket);
    }

    const closeSocket = () => {
        webSocket.current.close();
        window.removeEventListener('beforeunload', closeSocket);
    }

    // useInterval ----------------------------------------------------
    useInterval( () => {
        getActivatedRace();
    },1000);

    // useEffect ------------------------------------------------------
    useEffect(() => {
        if (!webSocket.current) { connectSocket();}
        return () => {
            if (webSocket.current) { closeSocket();}
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedRace) {
            selectRace(selectedRace);
        } else {
            setRaceTrack(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    // render ---------------------------------------------------------
    return (

        <Hpv2EngineContext.Provider value={{hpv2Engine, setHpv2Engine}}>
        <RacingEngineContext.Provider value={{racingEngine, setRacingEngine}}>
        <PositionRaceDataContext.Provider value={positionRaceData}>
        <TrackerSupervisionDataContext.Provider value={trackerSupervisionData}>
        <SelectedRaceContext.Provider value={{selectedRace, setSelectedRace}}>
        <IsEngineActiveContext.Provider value={{isEngineActive, setIsEngineActive}}>
        <CurrentRaceTrackContext.Provider value={raceTrack}>
        <div className="private_page_wrapper">
            <div className="pilotage_page_wrapper">
                <div className="pilotage_panel_data">
                    <div className="header">
                        <div className="sub_header_title">
                            <span className="title">
                                {t('monitoring.title')}
                                <Button
                                    onClick={() => {
                                        Modal.confirm({
                                            title: t('monitoring.modal.settings.title'),
                                            icon: null,
                                            content: <MonitoringSettings />,
                                            cancelText: t('monitoring.modal.settings.reload'),
                                            onCancel: () => {
                                                reloadDefaultMonitoringSettings()
                                                    .then(() => setColumnVersion(columnVersion+1));
                                            },
                                            okText: t('monitoring.modal.settings.close'),
                                            onOk: () => setColumnVersion(columnVersion+1),
                                        })
                                    }}
                                    className="btn-simple"
                                    icon={<SettingOutlined/>}
                                    />
                            </span>
                            <span className="subtitle">{t('monitoring.subtitle')}</span>
                        </div>
                        <div className="sub_header_action">
                            <Tooltip title="HPV2">
                                <div className={hpv2Connection ? "ws_connected" : "ws_unconnected"}>
                                    {hpv2Connection ? <WifiOutlined/> : <ApiOutlined/>}
                                </div>
                            </Tooltip>
                            <Tooltip title="RACING ENGINE">
                                <div className={engineConnection ? "ws_connected" : "ws_unconnected"}>
                                    <DeploymentUnitOutlined/>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <MonitoringSearchCriteria/>
                    <MonitoringDataList columnVersion={columnVersion}/>
                </div>
                <div className="pilotage_panel_map">
                    <MonitoringMap>
                    </MonitoringMap>
                    <MonitoringMapToolbar/>
                </div>
            </div>
        </div>
        </CurrentRaceTrackContext.Provider>
        </IsEngineActiveContext.Provider>
        </SelectedRaceContext.Provider>
        </TrackerSupervisionDataContext.Provider>
        </PositionRaceDataContext.Provider>
        </RacingEngineContext.Provider>
        </Hpv2EngineContext.Provider>
    )
}
