import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Drawer} from "antd";
import {useLocalStorage} from "../hooks/useLocalStorage";

import MLNbObjectSlider from "./MLNbObjectSlider";
import MLSwitch from "./MLSwitch";

import MapParametersService from "../services/map-parameters.service";
import {useScreenSize} from "../components/screensizeHook"

export default function MLDrawer({visible, onClose}) {
    const {t} = useTranslation();

    const [showRawPosition, setShowRawPosition] = useLocalStorage('monitoring-showRawPosition', false);
    const [showInnerTrack, setShowInnerTrack] = useLocalStorage('monitoring-showInnerTrack', true);
    const [showTrackGate, setShowTrackGate] = useLocalStorage('monitoring-showTrackGate', true);
    const [showOuterTrack, setShowOuterTrack] = useLocalStorage('monitoring-showOuterTrack', true);
    const [showComputedPosition, setShowComputedPosition] = useLocalStorage('monitoring-showComputedPosition', true);
    const [followRace, setFollowRace] = useLocalStorage('monitoring-followRace', true);
    const [mapType, setMapType] = useLocalStorage('monitoring-mapType', MapParametersService.MAP_TYPE_SATELLITE);
    const [fitBounds, setFitBounds] = useLocalStorage('monitoring-fitBounds', true);
    const [activateFilterOnNumber, setActivateFilterOnNumber] = useLocalStorage('monitoring-activateFilterOnNumber', false);
    const [activateFilterOnCurving, setActivateFilterOnCurving] = useLocalStorage('monitoring-activateFilterOnCurving', false);
    const [nbObjectOnMap, setNbObjectOnMap] = useLocalStorage('monitoring-nbObjectOnMap', MapParametersService.DEFAULT_NB_OBJECT_ON_MAP);
    const [size, setSize] = useState("normal");

    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    const screenSize = useScreenSize();
    useEffect(() => {
        var mode = screenSize.width < 1000 ? 'small': 'normal';
        if (size !== mode) {
            setSize(mode);
        }
    }, [screenSize]);

    return (
        <Drawer
            title={t('Map Control Options')}
            onClose={handleClose}
            visible={visible}
            placement='bottom'
            height={'250px'}
            id={'map_control'}
            className="map_control_body"
            >
            <div id={'map_control'}>
                <MLSwitch
                    size={size}
                    id="map_raw_position_switch"
                    text="Display raw race positions"
                    onChange={value => setShowRawPosition(value)}
                    checked={showRawPosition}/>
                <MLSwitch
                    size={size}
                    id="map_computed_position_switch"
                    text="Display computed race positions"
                    onChange={value => setShowComputedPosition(value)}
                    checked={showComputedPosition}/>
                <MLSwitch
                    size={size}
                    id="map_track_gates_switch"
                    text="Display intermediate gates"
                    onChange={value => setShowTrackGate(value)}
                    checked={showTrackGate}/>
                <MLSwitch
                    size={size}
                    id="map_inner_track_switch"
                    text="Display inner track"
                    onChange={value => setShowInnerTrack(value)}
                    checked={showInnerTrack}/>
                <MLSwitch
                    size={size}
                    id="map_inner_track_switch"
                    text="Display outer track"
                    onChange={value => setShowOuterTrack(value)}
                    checked={showOuterTrack}/>
                <MLSwitch
                    size={size}
                    id="fit_bounds_switch"
                    text="Fit bounds when following race on track"
                    onChange={value => setFitBounds(value)}
                    checked={fitBounds}/>
                <MLSwitch
                    size={size}
                    id="follow_race_on_track_switch"
                    text="Follow race on track"
                    onChange={value => setFollowRace(value)}
                    checked={followRace}/>
                <MLSwitch
                    size={size}
                    id="map_type_switch"
                    text="Map type"
                    checkedChildren={t('Satellite')}
                    unCheckedChildren={t('Roadmap')}
                    onChange={value => setMapType(value ? MapParametersService.MAP_TYPE_SATELLITE : MapParametersService.MAP_TYPE_ROADMAP)}
                    checked={mapType === MapParametersService.MAP_TYPE_SATELLITE}/>
                <MLSwitch
                    size={size}
                    id="map_object_filter_number"
                    text="Filter object on map by number"
                    onChange={value => setActivateFilterOnNumber(value)}
                    checked={activateFilterOnNumber}/>
                <MLSwitch
                    size={size}
                    id="map_object_filter_curving"
                    text="Filter object on map curve / line"
                    onChange={value => setActivateFilterOnCurving(value)}
                    checked={activateFilterOnCurving}/>
            </div>
            <div>
                <MLNbObjectSlider
                    size={size}
                    id="nb_map_complexity_objects"
                    text={"Nb track objects on Map"}
                    onChange={value => setNbObjectOnMap(value)}
                    value={nbObjectOnMap}
                    values={MapParametersService.POSSIBLES_NB_OBJECT_ON_MAP}
                    />
            </div>
        </Drawer>
    );
}
