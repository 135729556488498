import {useTranslation} from "react-i18next";
import {Switch} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export default function MLSwitch({id, text, onChange, checked, size, checkedChildren=<CheckOutlined />, unCheckedChildren=<CloseOutlined />}) {
    const {t} = useTranslation();
    return (
        <div className="item">
            <label className="label" htmlFor={id}>{t(text)}</label>
            <Switch
                className="switch"
                size={size}
                id={id}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                checked={checked}
                onChange={onChange}>
            </Switch>
        </div>
    )
}
