
const MAP_TYPE_SATELLITE = 'SATELLITE';
const MAP_TYPE_ROADMAP = 'ROADMAP';
const POSSIBLES_NB_OBJECT_ON_MAP = [500, 750, 1000, 1250, 1500, 2000, 2500];
const DEFAULT_NB_OBJECT_ON_MAP = 500;


const MapParametersService = {
    MAP_TYPE_SATELLITE,
    MAP_TYPE_ROADMAP,
    POSSIBLES_NB_OBJECT_ON_MAP,
    DEFAULT_NB_OBJECT_ON_MAP,
};

export default MapParametersService;