import React, {useEffect, useMemo, useState} from "react";
import {GoogleMap, LoadScript, Polyline} from "@react-google-maps/api";
import { useLocalStorage } from "../hooks/useLocalStorage";

// COMPONENTS
import Loader from "../components/loader"
import {useCurrentRaceTrackContext, usePositionRaceDataContext} from "../pages/privateMonitoring";
import {Button} from "antd";
import {UpOutlined} from "@ant-design/icons";
import ObjectFilterService from "../services/object-filter.service";
import MLMarkPoint from "./MLMarkPoint";
import MapParametersService from "../services/map-parameters.service";
import MLDrawer from "./MLDrawer";
import {useScreenSize} from "../components/screensizeHook"

// MAP containerStyle 
const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
};

var default_map_options = {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 6.0 // value for LEFT_BOTTOM
    },
    gestureHandling: "greedy", // cooperative
    disableDoubleClickZoom: true,
    minZoom: 3,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
    disableDefaultUI: true
}

export const MonitoringMap = React.forwardRef((props, ref) => {
    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState();
    const raceTrack = useCurrentRaceTrackContext();
    const positionRaceData = usePositionRaceDataContext();
    const [mapCenter, setMapCenter] = useState();
    const [mapZoom, setMapZoom] = useState(17);
    const [mapOptions, setMapOptions] = useState(false);
    const [innerTrackMarkerList, setInnerTrackMarkerList] = useState([]);
    const [outerTrackMarkerList, setOuterTrackMarkerList] = useState([]);
    const [pipelineList, setPipelineList] = useState([]);
    const [computedPositionMarkerList, setComputedPositionMarkerList] = useState([]);
    const [rawPositionMarkerList, setRawPositionMarkerList] = useState([]);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [toolbarButton, setToolbarButton] = useState();
    const [versionMapParams, setVersionMapParams] = useState(1);

    const [showRawPosition, setShowRawPosition, getInstantShowRawPosition] = useLocalStorage('monitoring-showRawPosition', false);
    const [showInnerTrack, setShowInnerTrack, getInstantShowInnerTrack] = useLocalStorage('monitoring-showInnerTrack', true);
    const [showTrackGate, setShowTrackGate, getInstantShowTrackGate] = useLocalStorage('monitoring-showTrackGate', true);
    const [showOuterTrack, setShowOuterTrack, getInstantShowOuterTrack] = useLocalStorage('monitoring-showOuterTrack', true);
    const [showComputedPosition, setShowComputedPosition, getInstantShowComputedPosition] = useLocalStorage('monitoring-showComputedPosition', true);
    const [followRace, setFollowRace, getInstantFollowRace] = useLocalStorage('monitoring-followRace', true);
    const [mapType, setMapType, getInstantMapType] = useLocalStorage('monitoring-mapType', MapParametersService.MAP_TYPE_SATELLITE);
    const [fitBounds, setFitBounds, getInstantFitBounds] = useLocalStorage('monitoring-fitBounds', true);
    const [activateFilterOnNumber, setActivateFilterOnNumber, getInstantActivateFilterOnNumber] = useLocalStorage('monitoring-activateFilterOnNumber', true);
    const [activateFilterOnCurving, setActivateFilterOnCurving, getInstantActivateFilterOnCurving] = useLocalStorage('monitoring-activateFilterOnCurving', true);
    const [nbObjectOnMap, setNbObjectOnMap, getInstantNbObjectOnMap] = useLocalStorage('monitoring-nbObjectOnMap', MapParametersService.DEFAULT_NB_OBJECT_ON_MAP);

    const showDrawer = () => {
        setVisibleDrawer(true);
    };
    const onCloseDrawer = () => {
        setVersionMapParams(versionMapParams+1);
        setVisibleDrawer(false);
    };

    // onMapLoad ------------------------------------------------------
    const onMapLoad = (map) => {
        setMap(map);
        if (mapType === MapParametersService.MAP_TYPE_ROADMAP) {
            map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        } else {
            map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        }
    };

    const clearMap = () => {
        if (!window.google || !map) {
            return;
        }
        setComputedPositionMarkerList([]);
        setInnerTrackMarkerList([]);
        setOuterTrackMarkerList([]);
        setPipelineList([]);
        for (let i = 0; i < rawPositionMarkerList.length; i++) {
            rawPositionMarkerList[i].marker.setMap(null);
        }
        rawPositionMarkerList.splice(0, rawPositionMarkerList.length);
        setRawPositionMarkerList([]);
    }

    const getToolbarButton = () => {
        return <Button type="primary" className="map_markers_drawer_btn" onClick={showDrawer}><UpOutlined /></Button>;
    }

    const cleanMarkers = (markers) => {
        markers.forEach(function(marker){
            marker.marker.setMap(null);
        });
    }

    const setComputedPositionData = (computedRacePositionData) => {
        if (!window.google || !map) {
            return;
        }
        // undraw the race data when marker list is set but empty computed race data are then sent
        if (computedRacePositionData && computedRacePositionData.length === 0 && computedPositionMarkerList.length > 0) {
            cleanMarkers(computedPositionMarkerList);
            setComputedPositionMarkerList([]);
            return;
        }
        if (!computedRacePositionData || computedRacePositionData.length === 0 || !computedPositionMarkerList) {
            return;
        }
        let computedPositionMarkerListCopy = computedPositionMarkerList.slice();
        for (let c = 0; c < computedRacePositionData.length; c++) {
            let obj = computedRacePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = computedPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showComputedPosition && obj.status === 'DP');
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showComputedPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num/${obj.number}.png`,
                        zindex: 1
                    })
                    computedPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

            // map follow
            if (raceTrack && followRace) {
                adjustCenterAndBound(computedPositionMarkerListCopy, false);
            }
        }
        setComputedPositionMarkerList(computedPositionMarkerListCopy);
    };

    const setRawPositionData = (racePositionData) => {
        if (!window.google || !map) {
            return;
        }
        if (!racePositionData || racePositionData.length === 0 || !rawPositionMarkerList) {
            return;
        }
        let rawPositionMarkerListCopy = rawPositionMarkerList.slice();
        for (let c = 0; c < racePositionData.length; c++) {
            let obj = racePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = rawPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showRawPosition);
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showRawPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num_raw_hpv2/${obj.number}.png`,
                        zindex: 1
                    })
                    rawPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

        }
        setRawPositionMarkerList(rawPositionMarkerListCopy);
    };

    // getTrack ----------------------------------------------------
    // display track on map
    const setTrack = (trackContent, params) => {
        if (trackContent) {
            const filteringModes = [];
            if(activateFilterOnNumber) {filteringModes.push(ObjectFilterService.FILTER_MODE_NUMBER)}
            if(activateFilterOnCurving) {filteringModes.push(ObjectFilterService.FILTER_MODE_CURVE)}
            const params = {
                modes: filteringModes,
                nbObjectOnMap: nbObjectOnMap,
                coefCurve: 0.002,
            }

            const {inners, outers, gates} = ObjectFilterService.filter(trackContent, params)

            const innerArrayMark = inners
                .map(item => (
                    <MLMarkPoint
                        key={item.type === 'gate' ? `marker_int_door_${item.index}` : `marker_int_other_${item.index}`}
                        visible={showInnerTrack}
                        position={item.position}
                        color={item.type === 'gate' ? 'green' : 'blue'}
                        visibleLabel={item.type === 'gate' ? showTrackGate : null}
                        label={item.type === 'gate' ? item.label : null}
                        />
                    )
                )

            const outerArrayMark = outers
                .map(item => (
                    <MLMarkPoint
                        key={item.type === 'gate' ? `marker_ext_door_${item.index}` : `marker_ext_other_${item.index}`}
                        visible={showOuterTrack}
                        position={item.position}
                        color={item.type === 'gate' ? 'green' : 'red'}
                        label={item.type === 'gate' ? item.label : null}
                        />
                    )
                )

            const pipelineList = gates
                .map(item => (
                    <Polyline
                        key={`polyline_${item.index}`}
                        path={item.positions}
                        />
                    )
                )

            setInnerTrackMarkerList(innerArrayMark);
            setOuterTrackMarkerList(outerArrayMark);
            setPipelineList(pipelineList);
            adjustCenterAndBound(innerArrayMark, true);
            map.setZoom(17);
        }
    };

    const adjustCenterAndBound = (data, useProps) => {
        if (raceTrack && followRace) {
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < data.length; i++) {
                if (useProps) {
                    bounds.extend(data[i].props.position)
                } else {
                    if (data[i].marker.visible) {
                        bounds.extend(data[i].marker.position)
                    }
                }
            }
            if (map) {
                map.setCenter(bounds.getCenter());
                if (fitBounds) {
                    map.fitBounds(bounds);
                }
            }
        }
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        if (loading) {
            if (window.google) {
                setToolbarButton(getToolbarButton());
                setMapOptions(default_map_options);
                setLoading(false);
                setMapCenter(JSON.parse(localStorage.getItem('mapDefaultCenter')));
                setMapZoom(Number(localStorage.getItem('mapDefaultZoom')));
            }
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.google]);

    useEffect(() => {
        if (map && raceTrack) {
            setTrack(raceTrack);
        } else {
            clearMap();
            setInnerTrackMarkerList([]);
            setOuterTrackMarkerList([]);
            setPipelineList([]);
            setComputedPositionMarkerList([]);
            setRawPositionMarkerList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, raceTrack, showInnerTrack, showTrackGate, showOuterTrack, followRace, fitBounds, activateFilterOnNumber, activateFilterOnCurving, nbObjectOnMap]);

    useEffect(() => {
        if (positionRaceData.raw_position) {
            setRawPositionData(positionRaceData.raw_position);
        }
        if (positionRaceData.computed_position) {
            setComputedPositionData(positionRaceData.computed_position);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionRaceData, showRawPosition, showComputedPosition, followRace]);

    useEffect(() => {
        setShowRawPosition(getInstantShowRawPosition())
        setShowInnerTrack(getInstantShowInnerTrack())
        setShowTrackGate(getInstantShowTrackGate())
        setShowOuterTrack(getInstantShowOuterTrack())
        setShowComputedPosition(getInstantShowComputedPosition())
        setFollowRace(getInstantFollowRace())
        setMapType(getInstantMapType())
        setFitBounds(getInstantFitBounds())
        setActivateFilterOnNumber(getInstantActivateFilterOnNumber())
        setActivateFilterOnCurving(getInstantActivateFilterOnCurving())
        setNbObjectOnMap(getInstantNbObjectOnMap())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [versionMapParams]);

    useEffect(() => {
        if (map) {
            if (mapType === MapParametersService.MAP_TYPE_ROADMAP) {
                map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
            } else {
                map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
            }
        }
    }, [mapType]);

    const screenSize = useScreenSize();
    useEffect(() => {
        var mode = screenSize.width < 1000 ? 'cooperative': 'greedy';
        if (map && default_map_options.gestureHandling !== mode) {
            default_map_options.gestureHandling = mode;
            map.setOptions({ gestureHandling: mode });
        }
    }, [screenSize, map]);

    const mapComponent = useMemo(() => {
        return <LoadScript
                   googleMapsApiKey="AIzaSyBRHVJET38Cx1CGYAkiErRdKh7gi2V4etM"
                   loadingElement={<Loader/>}>
                   { loading ?
                        <Loader/> :
                        <GoogleMap
                            id='pilotageMap'
                            mapContainerStyle={containerStyle}
                            zoom={mapZoom}
                            center={mapCenter}
                            onLoad={map => onMapLoad(map)}
                            options={mapOptions}
                            >
                            {props.children}
                            {innerTrackMarkerList}
                            {outerTrackMarkerList}
                            {pipelineList}
                            {toolbarButton}
                            <MLDrawer
                                visible={visibleDrawer}
                                onClose={() => onCloseDrawer()}
                                />
                        </GoogleMap>
                    }
                </LoadScript>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, innerTrackMarkerList, outerTrackMarkerList, positionRaceData, visibleDrawer])

    // render ---------------------------------------------------------
    return <> {mapComponent} </>
})

export default MonitoringMap;
