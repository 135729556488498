import {InfoWindow, Marker} from "@react-google-maps/api";

const ICON_SIZE = 10;


export default function MLMarkPoint({visible, position, color, visibleLabel, label}) {
    return (
        <Marker
            visible={visible}
            position={position}
            icon={{
                url: `${process.env.PUBLIC_URL}/images/icons/${color}_dot.png`,
                scaledSize: new window.google.maps.Size(ICON_SIZE, ICON_SIZE)
            }}>
            { visibleLabel && label ?
                <InfoWindow
                    position={position}
                    clickable={true}>
                    <p>{label}</p>
                </InfoWindow>:''
            }
        </Marker>
    );
}